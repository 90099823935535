import { AuthContext } from 'Contexts/Auth';
import { APIBase } from 'api/hosts';
import React, { useContext, useEffect, useState } from 'react';
import { FaArrowLeft, FaArrowRight, FaMusic, FaPlus, FaQrcode, FaSpinner } from 'react-icons/fa';
import QRCode from 'react-qr-code';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';

const extractVideoId = (url: string): string | null => {
  try {
    const urlObj = new URL(url);

    if (urlObj.hostname.includes('youtube.com')) {
      return urlObj.searchParams.get('v'); // Extract 'v' parameter for standard YouTube URLs
    }

    if (urlObj.hostname.includes('youtu.be')) {
      return urlObj.pathname.substring(1).split('?')[0]; // Extract video ID from shortened URLs
    }

    return null; // Unsupported format
  } catch (error) {
    console.error('Invalid URL:', url);
    return null;
  }
};

const PlaylistDetail: React.FC = () => {
  const { playlistId } = useParams<{ playlistId: string }>();
  const { currentToken } = useContext(AuthContext);
  const [playlist, setPlaylist] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [addVideoLoading, setAddVideoLoading] = useState<boolean>(false);
  const [newVideo, setNewVideo] = useState({ videoId: '', title: '' });
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [qrModalOpen, setQrModalOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (playlistId) {
      fetchPlaylistDetails();
      const interval = setInterval(fetchPlaylistDetails, 10000); // Fetch every 10 seconds
      return () => clearInterval(interval); // Clear interval on component unmount
    }
  }, [playlistId]);

  const fetchPlaylistDetails = async () => {
    try {
      const response = await fetch(`${APIBase}/client/playlist/${playlistId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setPlaylist(data.playlist);
      } else {
        throw new Error('Failed to fetch playlist details');
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Could not fetch playlist details.',
        icon: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleAddVideo = async () => {
    const { videoId: videoUrl, title } = newVideo;

    if (!videoUrl || !title.trim()) {
      Swal.fire({
        title: 'Error',
        text: 'Both video URL and title are required.',
        icon: 'error',
      });
      return;
    }

    const videoId = extractVideoId(videoUrl);

    if (!videoId) {
      Swal.fire({
        title: 'Error',
        text: 'Invalid YouTube URL.',
        icon: 'error',
      });
      return;
    }

    setAddVideoLoading(true);
    try {
      const response = await fetch(`${APIBase}/client/playlist/${playlistId}/addVideo`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ videoId, title, playlistId }),
      });

      if (response.ok) {
        Swal.fire({
          title: 'Success',
          text: 'Video added successfully.',
          icon: 'success',
        });
        fetchPlaylistDetails();
        setNewVideo({ videoId: '', title: '' });
        setModalOpen(false);
      } else {
        throw new Error('Failed to add video');
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Could not add video to the playlist.',
        icon: 'error',
      });
    } finally {
      setAddVideoLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="text-white min-h-screen flex items-center justify-center">
        <FaSpinner className="animate-spin text-4xl text-gray-400" />
      </div>
    );
  }

  if (!playlist) {
    return (
      <div className="text-white min-h-screen flex items-center justify-center">
        <p>Playlist not found.</p>
      </div>
    );
  }

  const unplayedVideos = playlist?.videos.filter((_: any, index: number) => index >= playlist.currentVideoIndex);

  const currentVideo = unplayedVideos?.[0] || null;
  const nextVideo = unplayedVideos?.[1] || null;
  const remainingVideos = unplayedVideos?.slice(2) || [];

  const currentUrl = window.location.href; // Get the current URL

  return (
    <div className="text-white min-h-screen bg-gray-900 p-4 flex flex-col space-y-4 py-20 relative">
      {/* Return Button */}
      <div className="relative w-full h-20">
        <button
          onClick={() => navigate(-1)}
          className="absolute top-4 left-4 bg-gray-700 text-white p-2 rounded-full hover:bg-gray-600 transition w-12 h-12 flex items-center justify-center"
        >
          <FaArrowLeft className="text-xl" />
        </button>

        {/* QR Button */}
        <button
          onClick={() => setQrModalOpen(true)}
          className="absolute top-4 right-20 bg-blue-600 text-white p-2 rounded-full hover:bg-blue-500 transition w-12 h-12 flex items-center justify-center"
        >
          <FaQrcode className="text-xl" />
        </button>
        <button
          onClick={() => setModalOpen(true)}
          className="absolute top-4 right-4 bg-green-600 text-white p-2 rounded-full flex items-center justify-center hover:bg-green-500 transition w-12 h-12 flex-shrink-0"
        >
          <div className="relative">
            <FaMusic className="text-xl" />
            <FaPlus className="absolute text-xs text-white top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-green-600 rounded-full" />
          </div>
        </button>
      </div>

      {/* Playlist Name */}
      <h2 className="text-2xl font-bold tracking-tight text-left mb-4">{playlist.name}</h2>

      {/* Now Playing */}
      <div className="bg-gray-800 p-4 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold mb-2 flex items-center">
          <FaMusic className="animate-bounce text-green-400 text-2xl mt-2 mr-2" />
          Now Playing
        </h3>
        {currentVideo ? (
          <div>
            <p className="font-bold">{currentVideo.title || 'Untitled Video'}</p>
            <p className="text-sm text-gray-400">Added by: {currentVideo.addedBy?.username || 'Unknown'}</p>
          </div>
        ) : (
          <p className="text-gray-400">No video is currently playing.</p>
        )}
      </div>

      {/* Up Next */}
      <div className="bg-gray-800 p-4 rounded-lg shadow-md">
        <h3 className="text-xl font-semibold mb-2 flex items-center">
          <FaArrowRight className="mr-2 text-blue-500" /> Up Next
        </h3>
        {nextVideo ? (
          <div>
            <p className="font-bold">{nextVideo.title || 'Untitled Video'}</p>
            <p className="text-sm text-gray-400">Added by: {nextVideo.addedBy?.username || 'Unknown'}</p>
          </div>
        ) : (
          <p className="text-gray-400">No upcoming video.</p>
        )}
      </div>

      {/* Video List */}
      <div className="bg-gray-800 p-4 rounded-lg shadow-md flex-1 overflow-auto">
        <h3 className="text-xl font-semibold mb-2">Following</h3>
        {remainingVideos.length > 0 ? (
          <ul className="space-y-2">
            {remainingVideos.map((video: any) => (
              <li key={video.videoId} className="p-2 rounded-lg bg-gray-700">
                <div>
                  <p className="font-bold text-sm">{video.title || 'Untitled Video'}</p>
                  <p className="text-xs text-gray-400">{video.addedBy?.username || 'Unknown'}</p>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-400">No remaining videos in the playlist.</p>
        )}
      </div>

      {/* Add Video Modal */}
      {modalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-96">
            <h3 className="text-xl font-semibold mb-4">Add New Video</h3>
            <div className="space-y-2">
              <input
                type="text"
                placeholder="Video Title"
                value={newVideo.title}
                onChange={(e) => setNewVideo({ ...newVideo, title: e.target.value })}
                className="w-full p-2 rounded-md bg-gray-700 text-white placeholder-gray-400"
              />
              <input
                type="text"
                placeholder="Video URL"
                value={newVideo.videoId}
                onChange={(e) => setNewVideo({ ...newVideo, videoId: e.target.value })}
                className="w-full p-2 rounded-md bg-gray-700 text-white placeholder-gray-400"
              />
              <button
                onClick={handleAddVideo}
                className={`w-full p-2 rounded-md flex items-center justify-center ${
                  addVideoLoading ? 'bg-gray-600' : 'bg-green-600 hover:bg-green-500'
                } text-white transition`}
                disabled={addVideoLoading}
              >
                {addVideoLoading ? <FaSpinner className="animate-spin mr-2" /> : <FaPlus className="mr-2" />}
                {addVideoLoading ? 'Adding...' : 'Add Video'}
              </button>
            </div>
            <button
              onClick={() => setModalOpen(false)}
              className="w-full mt-4 p-2 bg-red-600 text-white rounded-md hover:bg-red-500 transition"
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      {/* QR Modal */}
      {qrModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="bg-gray-800 p-6 rounded-lg shadow-lg w-96 text-center">
            <h3 className="text-xl font-semibold mb-4">Scan to Open Playlist</h3>
            <QRCode value={currentUrl} size={200} className="mx-auto" />
            <button
              onClick={() => setQrModalOpen(false)}
              className="w-full mt-4 p-2 bg-red-600 text-white rounded-md hover:bg-red-500 transition"
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PlaylistDetail;
