import { Route, Routes, Navigate } from 'react-router-dom';
import Karaoke from './KaraokeEvents';
import Map from './Map';
import Events from './PartyEvents';
import Bars from './Bars';
import Clubs from './Clubs';
import LowerBar from './LowerBar';
import BarsMap from './BarsMap';
import ClubsMap from './ClubsMap';
import BarDetail from './BarDetail';
import ClubDetail from './ClubDetail';
import MenuItemDetail from './MenuItemDetail'; // Import the new component
import RoomDetail from './RoomDetail'; // Import RoomDetail component
import PlaylistDetail from './PlaylistDetail'; // Import PlaylistDetail component


const PartyTicket = () => {
  return (
    <>
      <Routes>
        <Route path="events" element={<Events />} />
        <Route path="karaoke" element={<Karaoke />} />
        <Route path="map" element={<Map />} />
        <Route path="bars/map" element={<BarsMap />} />
        <Route path="bars/view-bar/:barId" element={<BarDetail />} />
        <Route path="clubs/map" element={<ClubsMap />} />
        <Route path="clubs/view-club/:clubId" element={<ClubDetail />} />
        <Route path="bars" element={<Bars />} />
        <Route path="clubs" element={<Clubs />} />
        <Route path="menu-item/:menuItemId" element={<MenuItemDetail />} /> {/* Add this line */}
        <Route path="room/:roomId" element={<RoomDetail />} /> {/* Add RoomDetail route */}
        <Route path="playlist/:playlistId" element={<PlaylistDetail />} /> {/* Add PlaylistDetail route */}
        
        <Route path="*" element={<Navigate to="events" />} />
      </Routes>
      <LowerBar />
    </>
  );
};

export default PartyTicket;
