import { APIBase } from 'api/hosts';
import { AuthContext } from 'Contexts/Auth';
import { useLanguage } from 'Contexts/LanguageContext';
import React, { useContext, useEffect, useState } from 'react';
import {
  FaChevronLeft,
  FaEllipsisV,
  FaFacebook,
  FaGlobe,
  FaInstagram,
  FaMapMarkerAlt,
  FaPlus,
  FaTiktok,
} from 'react-icons/fa';
import Modal from 'react-modal'; // For modal functionality
import QRCode from 'react-qr-code';
import { useNavigate, useParams } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom'; // Import Link for navigation

const translations = {
  english: {
    details: 'Venue Details',
    name: 'Name',
    address: 'Address',
    description: 'Description',
    latitude: 'Latitude',
    longitude: 'Longitude',
    venueTypes: 'Venue Types',
    schedule: 'Schedule',
    activities: 'Activities',
    viewOnMap: 'View on Map',
    back: 'Back to Venues',
    edit: 'Edit',
    delete: 'Delete',
    error: 'Error',
    deleteConfirmTitle: 'Are you sure?',
    deleteConfirmText: "You won't be able to revert this!",
    deleteSuccess: 'Venue has been deleted.',
    deleteFailure: 'Failed to delete venue. Please try again later.',
    fetchError: 'Failed to fetch venue. Please try again later.',
    loading: 'Loading...',
    rooms: 'Rooms',
    createRoom: 'Create Room',
    roomName: 'Room Name',
    roomCapacity: 'Room Capacity',
    roomImage: 'Room Image URL',
    create: 'Create',
    cancel: 'Cancel',
    roomSuccess: 'Room created successfully!',
    roomFailure: 'Failed to create room. Please try again later.',
  },
  // Add more languages as needed
};

const ViewVenue: React.FC = () => {
  const { venueId } = useParams<{ venueId: string }>();
  const [venueData, setVenueData] = useState<any>(null);
  const [activities, setActivities] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { currentToken } = useContext(AuthContext);
  const { language } = useLanguage();
  const navigate = useNavigate();
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const [rooms, setRooms] = useState<any[]>([]);
  const [isCreateRoomModalOpen, setIsCreateRoomModalOpen] = useState<boolean>(false);
  const [newRoomData, setNewRoomData] = useState({ name: '', capacity: 0, imageURL: '' });

  const t = translations[language as keyof typeof translations];

  useEffect(() => {
    fetchVenue();
    fetchActivities();
    fetchRooms();
  }, [venueId]);

  const fetchRooms = async () => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await fetch(`${APIBase}/client/room/byVenueId/${venueId}`, data);
      if (response.ok) {
        const result = await response.json();
        setRooms(result.rooms);
      } else {
        throw new Error(t.fetchError);
      }
    } catch {
      Swal.fire({
        title: t.error,
        text: t.fetchError,
        icon: 'error',
        timer: 3000,
      });
    }
  };

  const handleCreateRoom = async () => {
    const data: RequestInit = {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ ...newRoomData, venueId }),
    };

    try {
      const response = await fetch(`${APIBase}/client/room`, data);
      if (response.ok) {
        Swal.fire({
          title: t.roomSuccess,
          icon: 'success',
          timer: 2000,
        });
        fetchRooms(); // Refresh the list of rooms
        setIsCreateRoomModalOpen(false); // Close modal
        setNewRoomData({ name: '', capacity: 0, imageURL: '' }); // Reset form
      } else {
        throw new Error(t.roomFailure);
      }
    } catch {
      Swal.fire({
        title: t.error,
        text: t.roomFailure,
        icon: 'error',
        timer: 3000,
      });
    }
  };

  const fetchVenue = async () => {
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await fetch(`${APIBase}/client/venue/${venueId}`, data);
      if (response.ok) {
        const responseJson = await response.json();
        setVenueData(responseJson);
      } else {
        throw new Error(t.fetchError);
      }
    } catch {
      Swal.fire({
        title: t.error,
        text: t.fetchError,
        icon: 'error',
        timer: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchActivities = async () => {
    const data: RequestInit = {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };
    try {
      const response = await fetch(`${APIBase}/client/activity/venue/${venueId}`, data);
      if (response.ok) {
        const result = await response.json();
        setActivities(result.activities);
      } else {
        throw new Error(t.fetchError);
      }
    } catch {
      Swal.fire({
        title: t.error,
        text: t.fetchError + ' (Activities)',
        icon: 'error',
        timer: 3000,
      });
    }
  };

  const handleDelete = async () => {
    const data: RequestInit = {
      method: 'DELETE',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ venueId }),
    };
    await fetch(`${APIBase}/client/venue`, data)
      .then((response) => {
        if (response.ok) {
          Swal.fire({
            title: t.deleteSuccess,
            icon: 'success',
            timer: 2000,
          });
          navigate('/app/admin/venues');
        } else {
          Swal.fire({
            title: t.error,
            text: t.deleteFailure,
            icon: 'error',
            timer: 3000,
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: t.error,
          text: t.deleteFailure,
          icon: 'error',
          timer: 3000,
        });
      });
  };

  const confirmDelete = () => {
    Swal.fire({
      title: t.deleteConfirmTitle,
      text: t.deleteConfirmText,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: t.delete,
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete();
      }
    });
  };

  const generateGoogleMapsLink = () => {
    return `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(venueData.address)}`;
  };

  const generateAppleMapsLink = () => {
    return `http://maps.apple.com/?q=${encodeURIComponent(venueData.address)}`;
  };

  const handleViewOnMap = () => {
    const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    const mapLink = isIOS ? generateAppleMapsLink() : generateGoogleMapsLink();
    window.open(mapLink, '_blank');
  };

  if (loading) {
    return (
      <div className="text-white min-h-screen flex items-center justify-center">
        <StageSpinner size={50} color="#ffffff" />
      </div>
    );
  }

  if (!venueData) {
    return <div className="text-white min-h-screen flex items-center justify-center">{t.loading}</div>;
  }

  return (
    <>
      <Modal
        isOpen={isCreateRoomModalOpen}
        onRequestClose={() => setIsCreateRoomModalOpen(false)}
        contentLabel="Create Room Modal"
        className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-md mx-auto mt-20 text-white"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
      >
        <h2 className="text-2xl font-semibold mb-4">{t.createRoom}</h2>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">{t.roomName}</label>
            <input
              type="text"
              className="w-full p-2 rounded-md bg-gray-700 text-white"
              value={newRoomData.name}
              onChange={(e) => setNewRoomData({ ...newRoomData, name: e.target.value })}
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">{t.roomCapacity}</label>
            <input
              type="number"
              className="w-full p-2 rounded-md bg-gray-700 text-white"
              value={newRoomData.capacity}
              onChange={(e) => setNewRoomData({ ...newRoomData, capacity: Number(e.target.value) })}
            />
          </div>
          <div>
            <label className="block text-sm font-medium mb-1">{t.roomImage}</label>
            <input
              type="text"
              className="w-full p-2 rounded-md bg-gray-700 text-white"
              value={newRoomData.imageURL}
              onChange={(e) => setNewRoomData({ ...newRoomData, imageURL: e.target.value })}
            />
          </div>
        </div>
        <div className="flex justify-end mt-6 space-x-2">
          <button
            onClick={() => setIsCreateRoomModalOpen(false)}
            className="px-4 py-2 bg-gray-600 rounded-md hover:bg-gray-500 transition"
          >
            {t.cancel}
          </button>
          <button
            onClick={handleCreateRoom}
            className="px-4 py-2 bg-green-600 rounded-md hover:bg-green-500 transition"
          >
            {t.create}
          </button>
        </div>
      </Modal>
      <div className="text-white min-h-screen bg-gray-900 p-4 pt-20 pb-28">
        <button
          onClick={() => navigate('/app/admin/venues')}
          className="fixed top-20 left-4 p-3 bg-gray-800 text-white rounded-full shadow-lg hover:bg-gray-700 transition-all duration-300 ease-in-out"
        >
          <FaChevronLeft className="text-xl" />
        </button>
        <div className="max-w-4xl mx-auto mt-12">
          <div className="flex justify-between items-center mb-8">
            <h2 className="text-4xl font-extrabold tracking-tight">{t.details}</h2>
            <div className="relative">
              <button
                onClick={() => setDropdownOpen(!dropdownOpen)}
                className="px-2 py-1 bg-gray-700 text-white rounded-lg flex items-center hover:bg-gray-600"
              >
                <FaEllipsisV />
              </button>
              {dropdownOpen && (
                <div className="absolute right-0 mt-2 w-48 bg-white text-black rounded-lg shadow-xl z-10">
                  <button
                    onClick={() => navigate(`/app/admin/edit-venue/${venueId}`)}
                    className="block px-4 py-2 w-full text-left hover:bg-gray-100 transition duration-150"
                  >
                    {t.edit}
                  </button>
                  <button
                    onClick={confirmDelete}
                    className="block px-4 py-2 w-full text-left hover:bg-gray-100 transition duration-150"
                  >
                    {t.delete}
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
            <h3 className="text-3xl mb-4">{venueData.name}</h3>
            <p className="text-lg mb-4">
              <strong>{t.address}:</strong> {venueData.address}
            </p>
            <p className="text-lg mb-4">
              <strong>{t.description}:</strong> {venueData.description}
            </p>
            <button
              onClick={handleViewOnMap}
              className="flex items-center bg-gradient-to-r from-blue-600 to-blue-800 text-white p-3 rounded-full shadow-lg hover:from-blue-700 hover:to-blue-900 transition-all duration-300 ease-in-out mb-6"
            >
              <FaMapMarkerAlt className="mr-2 text-xl" />
              {t.viewOnMap}
            </button>

            {venueData.imageURL && (
              <img src={venueData.imageURL} alt={venueData.name} className="w-full h-72 object-cover rounded-md mb-6" />
            )}

            {venueData.schedule && (
              <div className="mt-6">
                <h4 className="text-2xl font-semibold mb-4">{t.schedule}</h4>
                <div className="space-y-2">
                  {venueData.schedule.map((day: any, index: number) => (
                    <div key={index} className="flex justify-between text-lg">
                      <span>{day.day}</span>
                      <span>
                        {day.times.length > 0
                          ? day.times.map((time: any) => `${time.open} - ${time.close}`).join(', ')
                          : 'Closed'}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {/* Activities Section */}
            <div className="mt-8">
              <h4 className="text-2xl font-semibold mb-4">{t.activities}</h4>
              <ul className="space-y-2">
                {activities.length > 0 ? (
                  activities.map((activity) => (
                    <li key={activity._id} className="flex justify-between items-center bg-gray-700 p-3 rounded-lg">
                      <span>
                        {activity.name} ({activity.points} points)
                      </span>
                    </li>
                  ))
                ) : (
                  <li className="text-gray-400">{t.loading}</li>
                )}
              </ul>
            </div>

            <p className="text-lg mt-6">
              <strong>{t.latitude}:</strong> {venueData.lat}
            </p>
            <p className="text-lg">
              <strong>{t.longitude}:</strong> {venueData.lng}
            </p>
            <p className="text-lg mt-4">
              <strong>{t.venueTypes}:</strong> {venueData.venueTypes.join(', ')}
            </p>

            <div className="flex flex-row space-x-4 my-4">
              {venueData.facebookLink && (
                <a
                  href={venueData.facebookLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white bg-gradient-to-r from-blue-500 to-blue-700 hover:from-blue-600 hover:to-blue-800 px-4 py-4 rounded-lg flex items-center justify-center"
                >
                  <FaFacebook className="text-2xl" />
                </a>
              )}
              {venueData.instagramLink && (
                <a
                  href={venueData.instagramLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white bg-gradient-to-r from-pink-500 to-pink-700 hover:from-pink-600 hover:to-pink-800 px-4 py-4 rounded-lg flex items-center justify-center"
                >
                  <FaInstagram className="text-2xl" />
                </a>
              )}
              {venueData.tiktokLink && (
                <a
                  href={venueData.tiktokLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white bg-gradient-to-r from-gray-600 to-gray-800 hover:from-gray-700 hover:to-gray-900 px-4 py-4 rounded-lg flex items-center justify-center"
                >
                  <FaTiktok className="text-2xl" />
                </a>
              )}
              {venueData.websiteLink && (
                <a
                  href={venueData.websiteLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-white bg-gradient-to-r from-green-500 to-green-700 hover:from-green-600 hover:to-green-800 px-4 py-4 rounded-lg flex items-center justify-center"
                >
                  <FaGlobe className="text-2xl" />
                </a>
              )}
            </div>

            <h3 className="text-xl mt-6">Venue QR Code</h3>
            <div className="flex justify-center mt-4 bg-white p-5 rounded-md shadow-md">
              <QRCode value={`venue-${venueData._id}`} size={128} />
            </div>
          </div>
          <div className="mt-8">
            <h4 className="text-2xl font-semibold mb-4">{t.rooms}</h4>
            <button
              onClick={() => setIsCreateRoomModalOpen(true)}
              className="flex items-center bg-gradient-to-r from-green-500 to-green-700 text-white px-4 py-2 rounded-full shadow-lg hover:from-green-600 hover:to-green-800 transition-all duration-300 ease-in-out mb-4"
            >
              <FaPlus className="mr-2" /> {t.createRoom}
            </button>
            <ul className="space-y-2">
              {rooms.length > 0 ? (
                rooms.map((room) => (
                  <li
                    key={room._id}
                    className="flex justify-between items-center bg-gray-700 p-3 rounded-lg hover:bg-gray-600 transition"
                  >
                    <Link
                      to={`/app/admin/view-room/${room._id}`}
                      className="flex justify-between items-center w-full text-white"
                    >
                      <div>
                        <p className="text-lg font-semibold">{room.name}</p>
                        <p className="text-sm text-gray-400">
                          {t.roomCapacity}: {room.capacity}
                        </p>
                      </div>
                      {room.imageURL && (
                        <img src={room.imageURL} alt={room.name} className="w-16 h-16 object-cover rounded-md" />
                      )}
                    </Link>
                  </li>
                ))
              ) : (
                <li className="text-gray-400">{t.loading}</li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewVenue;
