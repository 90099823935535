import { AuthContext } from 'Contexts/Auth';
import { APIBase } from 'api/hosts';
import React, { useContext, useEffect, useState } from 'react';
import { FaCalendar, FaFilter, FaTimes } from 'react-icons/fa';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { StageSpinner } from 'react-spinners-kit';
import Swal from 'sweetalert2';
import { Event } from 'types';
import DateFilter from './DateFilter';
import EventList from './EventList';
import FilterSection from './FilterSection';
import LowerBar from './LowerBar';
import SearchBar from './SearchBar';

interface FilteredEventsProps {
  eventType: string;
}

const FilteredEvents: React.FC<FilteredEventsProps> = ({ eventType }) => {
  const { currentToken, expiration } = useContext(AuthContext);
  const navigate = useNavigate();
  const [events, setEvents] = useState<Event[]>([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date(new Date().setDate(new Date().getDate() + 7)));
  const [searchQuery, setSearchQuery] = useState('');
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  const [selectedGenres, setSelectedGenres] = useState<string[]>([]);
  const [selectedVenueTypes, setSelectedVenueTypes] = useState<string[]>([]);
  const [ageRestriction, setAgeRestriction] = useState('');
  const [dressCode, setDressCode] = useState('');
  const [selectedClub, setSelectedClub] = useState<string | null>(null);
  const [isDateFilterModalOpen, setIsDateFilterModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!currentToken || (expiration && new Date(expiration) <= new Date())) {
      navigate('/auth/login');
    } else {
      fetchEvents();
    }
  }, [currentToken, expiration, navigate]);

  const fetchEvents = async () => {
    setLoading(true);
    const data: RequestInit = {
      method: 'GET',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'same-origin',
      headers: {
        Authorization: `Bearer ${currentToken}`,
        'Content-Type': 'application/json',
      },
    };

    await fetch(`${APIBase}/client/event`, data)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error('Error fetching events');
        }
      })
      .then((responseJson) => {
        console.log("responseJson> ", responseJson);
        setEvents(responseJson);
      })
      .catch((error) => {
        Swal.fire({
          title: 'Error',
          text: 'Failed to fetch events. Please try again later.',
          icon: 'error',
          timer: 3000,
        });
      });
    setLoading(false);
  };

  const handleClearFilters = () => {
    setStartDate(new Date());
    setEndDate(new Date(new Date().setDate(new Date().getDate() + 7)));
    setSearchQuery('');
    setSelectedGenres([]);
    setSelectedVenueTypes([]);
    setAgeRestriction('');
    setDressCode('');
    setSelectedClub(null);
  };

  const resetTimeToMidnight = (date: Date) => {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);
    return newDate;
  };
  
  console.log("events> ", events);

  const filteredEvents = events
    .filter((event) => {
      const eventDate = new Date(event.date);
      const isWithinDateRange =
        eventDate >= resetTimeToMidnight(startDate) && eventDate <= new Date(endDate.setHours(23, 59, 59, 999));
      const matchesSearchQuery =
        event.title?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.description?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        event.djs?.some((dj) => dj.toLowerCase().includes(searchQuery.toLowerCase())) ||
        event.genres?.some((genre) => genre.toLowerCase().includes(searchQuery.toLowerCase()));
      const matchesGenres =
        selectedGenres.length === 0 || event.genres?.some((genre) => selectedGenres.includes(genre));
      const matchesVenueTypes =
        selectedVenueTypes.length === 0 || event.clubId?.venueTypes?.some((type) => selectedVenueTypes.includes(type));
      const matchesAgeRestriction = ageRestriction === '' || event.ageRestriction === ageRestriction;
      const matchesDressCode = dressCode === '' || event.dressCode === dressCode;
      const matchesEventType = event.type?.includes(eventType);
      const matchesSelectedClub = selectedClub === null || event.clubId?._id === selectedClub;



      return (
        isWithinDateRange &&
        matchesSearchQuery &&
        matchesGenres &&
        matchesVenueTypes &&
        matchesAgeRestriction &&
        matchesDressCode &&
        matchesEventType &&
        matchesSelectedClub
      );
    })
    .sort((a, b) => {
      const dateComparison = new Date(a.date).getTime() - new Date(b.date).getTime();
      if (dateComparison !== 0) return dateComparison;
      if (a.network && !b.network) return -1;
      if (!a.network && b.network) return 1;
      return 0;
    });

    console.log("filteredEvents> ", filteredEvents);

  const anyFilterApplied =
    searchQuery !== '' ||
    selectedGenres.length > 0 ||
    selectedVenueTypes.length > 0 ||
    ageRestriction !== '' ||
    dressCode !== '';

  const handleShowCalendarFilter = () => {
    setIsDateFilterModalOpen(true);
  };

  const handleRemoveFilter = (filterType: string, value: string) => {
    switch (filterType) {
      case 'genre':
        setSelectedGenres(selectedGenres.filter((genre) => genre !== value));
        break;
      case 'venueType':
        setSelectedVenueTypes(selectedVenueTypes.filter((type) => type !== value));
        break;
      case 'ageRestriction':
        setAgeRestriction('');
        break;
      case 'dressCode':
        setDressCode('');
        break;
      default:
        break;
    }
  };

  return (
    <div className="w-screen h-screen flex flex-col justify-start items-start bg-cover bg-top bg-no-repeat bg-black">
      <div className="flex flex-col items-center w-full pb-10 h-screen overflow-scroll">
        <div className="w-full flex flex-col mt-20 py-1 mb-3 px-3 md:px-3 fixed bg-black bg-opacity-70 backdrop-blur-md z-10">
          <div className="flex flex-col md:flex-row items-center justify-center w-full mb-2 md:mb-0 ">
            <div className="w-full flex flex-row items-center justify-center mb-0 relative ">
              <FaFilter
                className="absolute mx-2 cursor-pointer text-black z-50 left-0 top-3"
                onClick={() => setShowAdvancedFilters(true)}
              />
              <SearchBar searchQuery={searchQuery} setSearchQuery={setSearchQuery} />
              <FaCalendar
                className={`mx-2 md:hidden cursor-pointer ${startDate || endDate ? 'text-white' : 'text-white'}`}
                onClick={handleShowCalendarFilter}
              />
            </div>
            <div className="flex-row w-full md:w-auto hidden md:flex">
              <DateFilter
                startDate={startDate}
                endDate={endDate}
                setStartDate={setStartDate}
                setEndDate={setEndDate}
                handleCloseModal={() => setIsDateFilterModalOpen(false)}
              />
            </div>
          </div>
          <div className="w-full flex flex-row">
            <div className="flex flex-row items-center mb-2 overflow-x-scroll scrollbar-hide">
              {selectedGenres.map((genre) => (
                <div
                  key={genre}
                  className="flex items-center bg-gray-700 text-white rounded-full px-3 py-1 m-1 shrink-0"
                >
                  {genre}
                  <FaTimes className="ml-2 cursor-pointer" onClick={() => handleRemoveFilter('genre', genre)} />
                </div>
              ))}
              {selectedVenueTypes.map((venueType) => (
                <div
                  key={venueType}
                  className="flex items-center bg-gray-700 text-white rounded-full px-3 py-1 m-1 shrink-0"
                >
                  {venueType}
                  <FaTimes className="ml-2 cursor-pointer" onClick={() => handleRemoveFilter('venueType', venueType)} />
                </div>
              ))}
              {ageRestriction && (
                <div className="flex items-center bg-gray-700 text-white rounded-full px-3 py-1 m-1 shrink-0">
                  {ageRestriction}
                  <FaTimes className="ml-2 cursor-pointer" onClick={() => handleRemoveFilter('ageRestriction', '')} />
                </div>
              )}
              {dressCode && (
                <div className="flex items-center bg-gray-700 text-white rounded-full px-3 py-1 m-1 shrink-0">
                  {dressCode}
                  <FaTimes className="ml-2 cursor-pointer" onClick={() => handleRemoveFilter('dressCode', '')} />
                </div>
              )}
            </div>
          </div>
          <div className="md:flex">
            <FilterSection
              selectedGenres={selectedGenres}
              setSelectedGenres={setSelectedGenres}
              selectedVenueTypes={selectedVenueTypes}
              setSelectedVenueTypes={setSelectedVenueTypes}
              ageRestriction={ageRestriction}
              setAgeRestriction={setAgeRestriction}
              dressCode={dressCode}
              setDressCode={setDressCode}
              handleClearFilters={handleClearFilters}
              showAdvancedFilters={showAdvancedFilters}
              setShowAdvancedFilters={setShowAdvancedFilters}
              anyFilterApplied={anyFilterApplied}
              setSelectedClub={setSelectedClub}
            />
          </div>
        </div>
        <div className="flex flex-col w-full mt-40">
          {loading ? (
            <div className="w-full flex items-center justify-center">
              <StageSpinner color="#ffffff" loading={loading} size={50} />
            </div>
          ) : (
            <EventList events={filteredEvents} singleList={false} />
          )}
        </div>
      </div>
      <LowerBar />
      <Modal
        isOpen={isDateFilterModalOpen}
        onRequestClose={() => setIsDateFilterModalOpen(false)}
        contentLabel="Select Date Range"
        className="fixed inset-0 flex items-center justify-center z-50 py-8"
        overlayClassName="fixed inset-0 bg-black bg-opacity-80 z-40"
        style={{
          content: {
            top: '20%',
            left: '10%',
            right: '10%',
            bottom: '20%',
            backgroundColor: 'black',
            borderRadius: '10px',
            padding: '20px',
          },
        }}
      >
        <DateFilter
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          handleCloseModal={() => setIsDateFilterModalOpen(false)}
        />
      </Modal>
    </div>
  );
};

export default FilteredEvents;
