import { Navigate, Route, Routes } from 'react-router-dom';
import CityDetail from './CityDetail';
import CityList from './CityList';
import CountryDetail from './CountryDetail';
import CountryList from './CountryList';
import CreateCity from './CreateCity';
import CreateCountry from './CreateCountry';
import CreateEvent from './CreateEvent';
import CreateMenuItem from './CreateMenuItem';
import CreateVenue from './CreateVenue';
import EditCity from './EditCity';
import EditCountry from './EditCountry';
import EditMenuItem from './EditMenuItem';
import EditVenue from './EditVenue';
import EventEditor from './EventEditor';
import EventList from './EventList';
import LowerBar from './LowerBar';
import MenuItemList from './MenuItemList';
import PlaylistDetail from './PlaylistDetail'; // Import PlaylistDetail component
import RoomDetail from './RoomDetail'; // Import the new RoomDetail component
import ScanDiscount from './ScanDiscount'; // New Import
import TemporaryVenuesAdder from './TemporaryVenuesAdder';
import VenueList from './VenueList';
import VenuesMap from './VenuesMap';
import ViewEvent from './ViewEvent';
import ViewMenuItem from './ViewMenuItem';
import ViewVenue from './ViewVenue';

const Admin = () => {
  return (
    <div>
      <Routes>
        {/* Other routes */}
        <Route path="create-venue" element={<CreateVenue />} />
        <Route path="new-event" element={<CreateEvent />} />
        <Route path="edit-event/:eventId" element={<EventEditor />} />
        <Route path="venues/map" element={<VenuesMap />} />
        <Route path="venues" element={<VenueList />} />
        <Route path="edit-venue/:venueId" element={<EditVenue />} />
        <Route path="view-venue/:venueId" element={<ViewVenue />} />
        <Route path="events" element={<EventList />} />
        <Route path="view-event/:eventId" element={<ViewEvent />} />
        <Route path="add-venues" element={<TemporaryVenuesAdder />} />
        <Route path="cities" element={<CityList />} />
        <Route path="countries" element={<CountryList />} />
        <Route path="view-city/:cityId" element={<CityDetail />} />
        <Route path="view-country/:countryId" element={<CountryDetail />} />
        <Route path="edit-city/:cityId" element={<EditCity />} />
        <Route path="edit-country/:countryId" element={<EditCountry />} />
        <Route path="new-city" element={<CreateCity />} />
        <Route path="new-country" element={<CreateCountry />} />
        <Route path="menu-items" element={<MenuItemList />} />
        <Route path="create-menu-item" element={<CreateMenuItem />} />
        <Route path="edit-menu-item/:menuItemId" element={<EditMenuItem />} />
        <Route path="view-menu-item/:menuItemId" element={<ViewMenuItem />} />
        <Route path="view-room/:roomId" element={<RoomDetail />} /> {/* New Route */}
        <Route path="view-playlist/:playlistId" element={<PlaylistDetail />} /> {/* New Route */}
        <Route path="scan-discount" element={<ScanDiscount />} /> {/* New Route */}
        <Route path="*" element={<Navigate to="events" />} />
      </Routes>
      <LowerBar />
    </div>
  );
};

export default Admin;
