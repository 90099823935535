import React, { useEffect, useState, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { AuthContext } from 'Contexts/Auth';
import { APIBase } from 'api/hosts';
import Swal from 'sweetalert2';
import { FaChevronLeft, FaMusic } from 'react-icons/fa';

const RoomDetail: React.FC = () => {
  const { roomId } = useParams<{ roomId: string }>();
  const { currentToken } = useContext(AuthContext);
  const navigate = useNavigate();
  const [room, setRoom] = useState<any>(null);
  const [playlists, setPlaylists] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    if (roomId) {
      fetchRoomDetails();
      fetchPlaylists();
    }
  }, [roomId]);

  const fetchRoomDetails = async () => {
    try {
      const response = await fetch(`${APIBase}/client/room/${roomId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setRoom(data.room);
      } else {
        throw new Error('Failed to fetch room details');
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Could not fetch room details.',
        icon: 'error',
      });
    }
  };

  const fetchPlaylists = async () => {
    try {
      const response = await fetch(`${APIBase}/client/playlist/byRoomId/${roomId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${currentToken}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setPlaylists(data.playlists);
      } else {
        throw new Error('Failed to fetch playlists');
      }
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Could not fetch playlists.',
        icon: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="text-white min-h-screen flex items-center justify-center">
        <p>Loading...</p>
      </div>
    );
  }

  if (!room) {
    return (
      <div className="text-white min-h-screen flex items-center justify-center">
        <p>Room not found.</p>
      </div>
    );
  }

  return (
    <div className="text-white min-h-screen bg-gray-900 p-4 pt-20">
      <button
        onClick={() => navigate(-1)}
        className="fixed top-20 left-4 p-3 bg-gray-800 text-white rounded-full shadow-lg hover:bg-gray-700 transition-all duration-300 ease-in-out"
      >
        <FaChevronLeft className="text-xl" />
      </button>
      <h2 className="text-4xl font-extrabold tracking-tight mb-6">{room.name}</h2>
      <div className="bg-gray-800 p-6 rounded-lg shadow-md">
        <h3 className="text-2xl font-semibold mb-4">Playlists</h3>
        {playlists.length > 0 ? (
          <ul className="space-y-4">
            {playlists.map((playlist) => (
              <li
                key={playlist._id}
                className="flex items-center justify-between bg-gray-700 p-4 rounded-lg cursor-pointer hover:bg-gray-600 transition"
                onClick={() => navigate(`/app/partyticket/playlist/${playlist._id}`)}
              >
                <div>
                  <p className="font-bold">{playlist.name}</p>
                  <p className="text-sm text-gray-400">{playlist.videos.length} videos</p>
                </div>
                <FaMusic className="text-green-400 text-xl" />
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-400">No playlists in this room.</p>
        )}
      </div>
    </div>
  );
};

export default RoomDetail;
